import { CloseSharp } from '@/app/components/icons/CloseSharp';
import { Button } from '@/v2Components/Button/Button';

type CloseButtonProps = {
  id: string;
  onClick: () => void;
  mixpanelButtonText: string;
  ariaLabel: string;
};

const CloseButton = ({
  id,
  onClick,
  mixpanelButtonText,
  ariaLabel,
}: CloseButtonProps) => {
  return (
    <Button
      id={id}
      onClick={onClick}
      mixpanelButtonText={mixpanelButtonText}
      variant="icon-square"
      ariaLabel={ariaLabel}
    >
      <CloseSharp />
    </Button>
  );
};

export { CloseButton, type CloseButtonProps };
