'use client';

import { useAnalytics } from '@/app/components/Analytics';
import { usePartner } from '@/lib/partners/usePartner/client';
import classNames from 'classnames';
import NextLink from 'next/link';
import { ReactNode } from 'react';
import { Loader } from '../Loader';

type IconProps = {
  icon: ReactNode;
  position: 'left' | 'right';
  slideOnHover?: boolean;
};

type ButtonProps = {
  id: string;
  children: ReactNode;
  onClick?: () => void;
  mixpanelButtonText: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'icon-square';
  icon?: IconProps;
  disabled?: boolean;
  loading?: boolean;
  loadingLabel?: string;
  ariaLabel?: string;
  implementAs?: 'button' | 'a' | 'NextLink';
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const Button = ({
  id,
  children,
  onClick,
  mixpanelButtonText,
  type = 'button',
  icon,
  disabled = false,
  loading = false,
  loadingLabel = 'Loading',
  variant = 'primary',
  ariaLabel,
  implementAs = 'button',
  href = '/',
  target = '_self',
}: ButtonProps) => {
  const analytics = useAnalytics();
  const partner = usePartner();
  const Tag = implementAs;

  let variantClassNames: string = '';

  const commonStyles = [
    'transition-colors',
    'duration-200',
    'w-full',
    'tablet:min-h-10',
    'min-h-9',
    'text-body-4',
    'tablet:text-body-3',
    'flex',
    'items-center',
    'justify-center',
    'relative',
    'group',
    {
      'flex-row': icon?.position === 'left',
      'flex-row-reverse': icon?.position === 'right',
    },
  ];

  switch (variant) {
    case 'primary': {
      variantClassNames = classNames(
        ...commonStyles,
        'text-center',
        'rounded-3xl',
        'px-k-7',
        'border-2',
        'focus:text-core-9',
        'focus:bg-buttonBackgroundColorHover',
        'focus:underline',
        'focus:underline-offset-2',
        {
          'bg-buttonBackgroundColor': !disabled,
          'hover:bg-buttonBackgroundColorHover': !disabled,
          'text-buttonTextColor': !disabled,
          // do properly once other buttons are converted
          // 'hover:text-buttonTextColorHover',
          'hover:text-core-8': !disabled,
          'border-core-8': !disabled,
          'pointer-events-none': disabled,
          'text-core-7': disabled,
          'bg-core-3': disabled,
          'border-core-3': disabled,
        },
      );
      break;
    }
    case 'secondary': {
      variantClassNames = classNames(
        ...commonStyles,
        'text-center',
        'rounded-3xl',
        'px-k-7',
        'border-2',
        'focus:bg-buttonBackgroundColorHover',
        'focus:underline',
        'focus:underline-offset-2',
        'focus:border-4',
        'focus:text-core-8',
        {
          'bg-core-8': !disabled,
          'text-buttonTextColor': !disabled,
          'border-core-8': !disabled,
          'pointer-events-none': disabled,
          'text-core-7': disabled,
          'bg-core-3': disabled,
          'border-core-3': disabled,
        },
      );
      break;
    }
    case 'tertiary': {
      variantClassNames = classNames(
        ...commonStyles,
        'focus:text-core-8',
        'focus:underline',
        'focus:underline-offset-2',
        'focus:outline-none',
        {
          'text-core-7': !disabled,
          'hover:text-core-8': !disabled,
          'hover:underline': !disabled,
          'hover:underline-offset-2': !disabled,
          'pointer-events-none': disabled,
          'text-core-6': disabled,
        },
      );
    }
  }

  let anchorProps: { target?: string } = {};
  if (implementAs === 'a') {
    anchorProps['target'] = target;
  }

  return Tag === 'NextLink' ? (
    <NextLink
      href={href}
      className={classNames(variantClassNames, {
        'pointer-events-none': loading || disabled,
      })}
      aria-label={ariaLabel}
      aria-disabled={loading || disabled}
      tabIndex={loading || disabled ? -1 : undefined}
      onClick={onClick}
    >
      {loading ? (
        <>
          <span className="block h-k-8">
            <Loader colour="stroke-core-8" />
          </span>
          <span className="sr-only">{loadingLabel}</span>
        </>
      ) : (
        children
      )}
    </NextLink>
  ) : (
    <Tag
      id={id}
      className={variantClassNames}
      onClick={() => {
        if (!loading) {
          onClick?.();
          analytics?.trackButtonClick(partner, mixpanelButtonText, id);
        }
      }}
      type={type}
      disabled={disabled}
      aria-label={ariaLabel}
      href={href}
      {...anchorProps}
    >
      {loading ? (
        <>
          <span className="block h-k-8">
            <Loader colour="stroke-core-8" />
          </span>
          <span className="sr-only">{loadingLabel}</span>
        </>
      ) : icon ? (
        <>
          <span
            className={classNames(
              'h-k-7',
              'w-k-7',
              'transition-transform duration-200',
              {
                'mr-k-4': icon?.position === 'left',
                'ml-k-4': icon?.position === 'right',
                'group-hover:-translate-x-k-4':
                  icon?.position === 'left' && icon?.slideOnHover,
                'group-hover:translate-x-k-4':
                  icon?.position === 'right' && icon?.slideOnHover,
              },
            )}
          >
            {icon.icon}
          </span>
          {children}
        </>
      ) : (
        children
      )}
    </Tag>
  );
};

export { Button, type ButtonProps };
